import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Young Graduate Trainees (YGT) at the ACT",
  "layout": "aboutpage",
  "banner": "/images/core/team_banner.jpg",
  "banner_caption": "join"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The Advanced Concepts Team hires every year several Young Graduate Trainees (YGTs) as part of the YGT program of ESA.
These positions are of interest for recently graduated students (master degree or equivalent) who would like to join us for
1 year.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.esa.int/About_Us/Careers_at_ESA/Graduates_Young_Graduate_Trainees"
      }}>{`Further information about ESA's YGT programme`}</a></p>
    <p>{`Positions are typically open around February each year. The above page contains
further links to the application portal and highlights all necessary requirements.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      